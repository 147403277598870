import { createReducer, on } from '@ngrx/store';
import { OrderActions } from './order.actions';

export const orderFeatureKey = 'order';

export interface State {
  data: any

}

export const initialState: State = {
  data: null

};

export const reducer = createReducer(
  initialState,
  on(
    OrderActions.loadOrdersSuccess,
    (state, action) => {
      return {
        ...state,
        data: action.data
      }
    }
  )
);

