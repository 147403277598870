import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const OrderActions = createActionGroup({
  source: 'Order',
  events: {
    'Load Orders': emptyProps(),
    'Load Orders Success': props<{data: any}>(),
    'Load Single Order': props<{ order_id: number }>(),
    'Load Single Order Success': props<{ data: any }>(),
    'Cancel Order Item': props<{ attribute_order_product_id: number, cancel_reason: string }>(),
    'Change Delivery Address': props<{ delvery_address_id: number }>()
  }
});
