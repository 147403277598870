import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { ApiHttpService } from './api-http.service';
import { Router } from '@angular/router';
import { ErrorNotification } from 'rxjs';
import { API_ROUTES } from '../constants/api.constants';
import * as CryptoJS from "crypto-js";

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(
    private _auth: AuthService,
    private _api: ApiHttpService,
    private _router: Router
  ) { }

  performPayment(method: string, amount: number, id: number, payable_amount = 0) {
    let params: NonNullable<unknown> | null;
    let path: string;
    let sub: any;
    const success_url = document.location.origin + '/orders/paymentVerfication/' + id + '/' + method + '/success';
    const fail_url = document.location.origin + '/orders/paymentVerfication/' + id + '/' + method + '/fail';
    switch (method) {
      case 'esewa':
        const total_amount = payable_amount ?? amount;
        const uuid = id+'-'+Date.now();
        const message = 'total_amount='+total_amount+',transaction_uuid='+uuid+',product_code='+environment.PAYMENT_URL.ESEWA_SCD;
        const hash = CryptoJS.enc.Base64.stringify(CryptoJS.HmacSHA256(message,environment.PAYMENT_URL.ESEWA_SECRET_KEY))
        path = environment.PAYMENT_URL.ESEWA;
        params = {
          amount: total_amount,
          tax_amount: 0,
          transaction_uuid: uuid,
          total_amount: total_amount,
          product_code: environment.PAYMENT_URL.ESEWA_SCD,
          product_service_charge: 0,
          product_delivery_charge: 0,
          success_url: success_url,
          failure_url: fail_url,
          signed_field_names: 'total_amount,transaction_uuid,product_code',
          signature: hash
        }
        this.submitForm('POST', path, params, null);
        break;
      case 'khalti':
        sub = this._api.post(API_ROUTES.khaltiPayment, { order_id: id }).subscribe({
          next: (res: any) => {
            location.href = res.data.payment_url;
          },
          complete: () => {
            sub.unsubscribe();
          },
          error: (err: ErrorNotification) => {
            sub.unsubscribe();
          }
        });
        break;
      case 'mypay':
        sub = this._api.post(API_ROUTES.myPayPayment, { order_id: id }).subscribe({
          next: (res: any) => {
            location.href = res.data.redirect_url;
          },
          complete: () => {
            sub.unsubscribe();
          },
          error: (err: ErrorNotification) => {
            sub.unsubscribe();
          }
        });
        break;
      case 'card':
        const url = `${environment.API_ENDPOINT}/api/cardPay/${id}/proceed`;
        const authToken = this._auth.getToken()
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.setRequestHeader('Authorization', `Bearer ${authToken}`);
        xhr.onreadystatechange = function () {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              document.write(xhr.response);
            } else {
              console.error('Request failed with status:', xhr.status);
            }
          }
        };
        xhr.send();
        break;
      default:
        this._router.navigate(['/thank-you', id]);
        break;
    }
  }

  submitForm(method: 'GET' | 'POST', path: string, params: any, headers: any) {
    const form = document.createElement("form");
    form.setAttribute("method", method);
    form.setAttribute("action", path);

    for (const key in params) {
      const hiddenField = document.createElement("input");
      hiddenField.setAttribute("type", "text");
      hiddenField.setAttribute("name", key);
      hiddenField.setAttribute("id", key);
      hiddenField.setAttribute("value", params[key]);
      hiddenField.setAttribute("required", "true");
      form.appendChild(hiddenField);
    }

    for (const key in headers) {
      const headerField = document.createElement("input");
      headerField.setAttribute("type", "hidden");
      headerField.setAttribute("name", key);
      headerField.setAttribute("value", headers[key]);
      form.appendChild(headerField);
    }

    document.body.appendChild(form);
    form.submit();
  }
}
